function UploadController(Uploader, $log, $mdDialog) {
  this.buttonText = 'Drop File or Click';

  this.files = [];

  this.uploadingFiles = [];

  this.$onInit = () => {
    // Initialize our files object ot user the Uploader.files object
    // This holds a reference to our files object
    // So that if any jobs are in progress, we will see evidence of that upon
    // routing back to this component
    this.files = Uploader.files.length ? Uploader.files : [];
  };

  this.uploadFiles = () => {
    //TODO: finish implementing error handling in upload
    //this catch will never be triggerred at the moment
    Uploader.upload(this.files, this.job).catch((rejection) => {
      $log.debug('upload rejected', rejection);
    });
  };

  this.cancelUpload = (file) => {
    if(file.upload) {
      Uploader.cancelUpload(file);
    } else {
      // If not uploading yet Uploader doesn't know about it
      const index = this.files.indexOf(file);

      if(index > -1) {
        this.files.splice(index, 1);
      }
    }
  };

  this.cancelAllUploads = () => {
    const files = Uploader.files.concat([]);

    // Without copyin the array, the array will get modified, and the foreach
    // Will prematurely stop
    files.forEach((file) => {
      this.cancelUpload(file);
    });
  };

  this.isUploading = () => {
    return Uploader.running;
  };

  //TODO: move this somewhere else
  this.showS3dialog = (ev) => {
    $mdDialog.show({
      controller: DialogController,
      // templateUrl: 'jobs/upload/jobs.upload.cloud.dialog.tpl.html',
      // Due to known angular material but, must wrap in md-dialog
      template: `
      <md-dialog flex="66">
          <sq-cloud-upload on-cancelled='cancel()' on-selected='save(files)'></sq-cloud-upload>
      </md-dialog>
      `,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose:true,
      fullscreen: true, //-xs and -sm breakpoints
    })
    // If files are provided, use them, if not, revert to the local files uploader
    .then((files) => {
      if(files) {
        this.files = files;
      }
    })
    .catch((rejection) => {
      $log.error(rejection);
    });
  };

  function DialogController($scope, $mdDialog) {
    $scope.cancel = function() {
      $mdDialog.cancel();
    };

    $scope.save = function(files) {
      $mdDialog.hide(files);
    };
  }
}

angular.module('sq.jobs.upload.component',
['sq.jobs.upload.service', 'sq.jobs.upload.cloud.component', 'ngFileUpload'])
.component('sqUpload', {
  bindings: {
    backFunc: '&',
    title: '@',
    subTitle: '@',
    job: '<',
  },
  controller: UploadController,
  templateUrl: 'jobs/upload/jobs.upload.tpl.html',
  transclude: true,
});
